import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { makeStyles } from "@material-ui/styles";
import Link from "../Link";
import ReactHtmlParser from "react-html-parser";
import SubNav from "./subNav";
import styles from "./styles";
import Img from "gatsby-image";
import scrollTo from "gatsby-plugin-smoothscroll";

import isoMarks from "../../images/iso-marks.png";

import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import InstagramIcon from "@material-ui/icons/Instagram";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import YouTubeIcon from "@material-ui/icons/YouTube";

const useStyles = makeStyles((theme) => styles(theme));

export default function Footer(props) {
  const classes = useStyles();
  const data = useStaticQuery(graphql`
    query FooterItems {
      allWpMenu(filter: { locations: { in: FOOTER_MENU } }) {
        edges {
          node {
            id
            name
            locations
            slug
            menuItems {
              nodes {
                id
                title
                url
                path
                label
                target
                order
                childItems {
                  nodes {
                    id
                    title
                    url
                    path
                    label
                    target
                    order
                  }
                }
              }
            }
          }
        }
      }
      wp {
        locationData {
          LocationData {
            address1
            address2
            email
            facebookHandle
            town
            instagramHandle
            twitterHandle
            linkedinUrl
            youtubeUrl
            name
            postcode
            telephone
            region
          }
        }
        themeOptions {
          ThemeOptions {
            footerLogo {
              sourceUrl
            }
            footerIcons {
              icon {
                id
                localFile {
                  childImageSharp {
                    fluid(quality: 80, maxWidth: 1660) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
              text
            }
          }
        }
      }
    }
  `);

  return (
    <>
      <div className={`icon-list ${classes.iconRow}`}>
        {data.wp.themeOptions.ThemeOptions.footerIcons.map((icon, index) => {
          return (
            <div className={`icon-list ${classes.iconRowInner}`} key={index}>
              <Img fluid={icon.icon.localFile.childImageSharp.fluid} />
              <span>{icon.text}</span>
            </div>
          );
        })}
      </div>
      <div className={`footer ${classes.footer}  ${classes.footerTheme}`}>
        <button className="back-to-top" onClick={() => scrollTo(`body`)}>
          <span>Top</span>
        </button>

        <div className="logo-col">
          <Link to="/">
            <img
              src={data.wp.themeOptions.ThemeOptions.footerLogo.sourceUrl}
              alt={data.wp.locationData.LocationData.name}
            />
          </Link>
          <img src={isoMarks} className="iso-marks" alt="" />
        </div>

        <div className="nav-col">
          <p className="footer-h">Links</p>
          <div className="footer-nav">
            {data.allWpMenu.edges[0].node.menuItems.nodes.map((section) => (
              <Link key={section.id} to={section.path}>
                {ReactHtmlParser(section.label)}
              </Link>
            ))}
          </div>
        </div>

        <div className="contact-col">
          <p className="footer-h">Contact Us</p>
          <p className="address">
            {data.wp.locationData.LocationData.address1 !== null && (
              <span>{data.wp.locationData.LocationData.address1}, </span>
            )}
            {data.wp.locationData.LocationData.address2 !== null && (
              <span>{data.wp.locationData.LocationData.address2}, </span>
            )}
            {data.wp.locationData.LocationData.town !== null && (
              <span>{data.wp.locationData.LocationData.town}, </span>
            )}
            {data.wp.locationData.LocationData.region !== null && (
              <span>{data.wp.locationData.LocationData.region} </span>
            )}
            <span>United Kingdom </span>
            {data.wp.locationData.LocationData.postcode !== null && (
              <span>{data.wp.locationData.LocationData.postcode} </span>
            )}
          </p>
          <Link
            className="phone"
            to={"tel:" + data.wp.locationData.LocationData.telephone}
          >
            {data.wp.locationData.LocationData.telephone}
          </Link>
          <Link
            className="email"
            to={"mailto:" + data.wp.locationData.LocationData.email}
          >
            Email us
          </Link>
        </div>

        <div className="social-col">
          <p className="footer-h">Follow us</p>
          {data.wp.locationData.LocationData.facebookHandle !== null && (
            <Link
              className="social-link"
              to={data.wp.locationData.LocationData.facebookHandle}
            >
              Connect on Facebook <FacebookIcon />
            </Link>
          )}
          {data.wp.locationData.LocationData.twitterHandle !== null && (
            <Link
              className="social-link"
              to={
                "https://www.twitter.com/" +
                data.wp.locationData.LocationData.twitterHandle
              }
            >
              Follow on Twitter <TwitterIcon />
            </Link>
          )}
          {data.wp.locationData.LocationData.instagramHandle !== null && (
            <Link
              className="social-link"
              to={data.wp.locationData.LocationData.instagramHandle}
            >
              Follow on Instagram <InstagramIcon />
            </Link>
          )}
          {data.wp.locationData.LocationData.linkedinUrl !== null && (
            <Link
              className="social-link"
              to={data.wp.locationData.LocationData.linkedinUrl}
            >
              Connect on Linkedin <LinkedInIcon />
            </Link>
          )}
          {data.wp.locationData.LocationData.youtubeUrl !== null && (
            <Link
              className="social-link"
              to={data.wp.locationData.LocationData.youtubeUrl}
            >
              Watch on YouTube <YouTubeIcon />
            </Link>
          )}
        </div>

        <div className="credit-col">
          <p>
            <span>© 2023 Resan is a trading name of Thomas Dudley Ltd.</span>
            <span>Registered in England No.2399487</span>
            <span>
              Powered by <Link to="https://www.wearebrew.co.uk">Brew</Link>
            </span>
          </p>
        </div>

        <div className="access-nav-col">
          <SubNav />
        </div>
      </div>
    </>
  );
}
