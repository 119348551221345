import Cookies from "js-cookie";
import { isBrowser } from "../helpers/index";
import {
  SET_LAYOUT,
  SET_SORT,
  SET_VISIBLE_ITEMS,
  SET_FILTER,
  WISHLIST_ADD_ITEM,
  WISHLIST_REMOVE_ITEM,
  OPEN,
  CLOSE,
  SET_PAGE,
} from "./types";

const initialShopState = {
  layout: "grid",
  sort: "name",
  itemsToShow: 12,
  page: 1,
  filter: {
    bathroom: false,
    kitchen: false,
  },
};

function getInitialWIshlistState() {
  if (!isBrowser) return [];

  const cookie = Cookies.get("wishlist");

  if (cookie) {
    return JSON.parse(Cookies.get("wishlist"));
  } else {
    return [];
  }
}

const initialWishlistState = getInitialWIshlistState();

export function ShopSettings(state = initialShopState, action) {
  switch (action.type) {
    case SET_LAYOUT:
      return { ...state, layout: action.layout };
    case SET_SORT:
      return { ...state, sort: action.sort };
    case SET_VISIBLE_ITEMS:
      return { ...state, itemsToShow: action.number };
    case SET_FILTER:
      return { ...state, filter: action.filter };
    case SET_PAGE:
      return { ...state, page: action.page };
    default:
      return state;
  }
}

export function WishlistSettings(state = initialWishlistState, action) {
  switch (action.type) {
    case WISHLIST_ADD_ITEM:
      if (state.includes(action.item)) return state;
      return [...state, action.item];
    case WISHLIST_REMOVE_ITEM:
      return state.filter((item) => item !== action.item);
    default:
      return state;
  }
}

export function OverlaySettings(state = { opened: false }, action) {
  switch (action.type) {
    case OPEN:
      return { opened: true };
    case CLOSE:
      return { opened: false };
    default:
      return state;
  }
}
