/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import CssBaseline from "@material-ui/core/CssBaseline";
import { Helmet } from "react-helmet";
import TopNav from "./TopNav";
import Meta from "./Meta";
import "./layout.scss";
import Footer from "./Footer";
import Toast from "./Toast";
import GlobalAlert from "./GlobalAlert";
import { store } from "../app/store";

import CookieConsent from "react-cookie-consent";

const Layout = (props) => {
  return (
    <>
      <Provider store={store}>
        <CssBaseline />
        <style>
          {
            "html,body{max-width:100%;overflow-x:hidden;}body{position:relative}img{max-width:100%;}.gatsby-image-wrapper{position:relative;}.gatsby-image-wrapper picture{position:absolute;top:0;right:0;bottom:0;left:0;}.mb-0{margin-bottom: 0 !important;"
          }
        </style>
        <Meta meta={props.meta} title={props.title} path={props.path} />
        <Helmet>
          <script
            type="text/javascript"
            async={true}
            src="https://embed.tawk.to/6525431ceb150b3fb9a00009/1hccosagn"
            charSet="UTF-8"
            crossOrigin="*"
          ></script>
          <script>
            {`
            /* Google Tag Manager / Google Analytics and Consent */
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}

            <!-- Google Tag Manager -->
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','` + process.env.GATSBY_GOOGLE_TAG_MANAGER_KEY + `');
            <!-- End Google Tag Manager -->
            `}
          </script>
        </Helmet>
        <TopNav home={props.path === "/" ? true : false} />
        <div
          style={{
            margin: `0 auto`,
            maxWidth: 1280,
            padding: `0 1.0875rem`,
          }}
        >
          <main>{props.children}</main>
          <Footer />
          <Toast />
        </div>
        <GlobalAlert />

        <CookieConsent
          location="bottom"
          buttonText="Accept"
          cookieName="cookie-consent"
          style={{
            background: "#ffffff",
            color: "#000000",
            zIndex: "2000000001",
          }}
          buttonStyle={{
            color: "#ffffff",
            fontSize: 14,
            backgroundColor: "#101c32",
            padding: "10px 20px",
            borderRadius: 25,
            fontFamily: "prometo, sans-serif",
            fontWeight: "600",
            letterSpacing: "0.016em",
            boxShadow: "0 4px 6px rgba(0,0,0,0.16)",
          }}
          expires={150}
        >
          We use essential cookies to ensure correct website operation and also non-essential cookies which may be used to
          personalise content and ads and analyse our traffic. You can find out more information in our{" "}
          <a href="/privacy-policy">Cookie Policy</a> and our <a href="/privacy-policy">Privacy Policy</a>.{" "}
        </CookieConsent>
      </Provider>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  meta: PropTypes.object.isRequired,
};

export default Layout;
