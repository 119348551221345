import React from "react";
import { connect } from "react-redux";
import { useStaticQuery, graphql } from "gatsby";
import { makeStyles } from "@material-ui/styles";
import Link from "../Link";
import ReactHtmlParser from "react-html-parser";
import styles from "./styles";
import DesktopNav from "./DesktopNav";
import Img from "gatsby-image";

import tdLogo from "../../images/td-logo.png";

import search from "../../images/nav-search.png";
import star from "../../images/quote-icon.png";

const useStyles = makeStyles((theme) => styles(theme));

const mapStateToProps = (state) => ({
  wishlist: state.WishlistSettings,
});

const connector = connect(mapStateToProps, null);

function TopNav(props) {
  const [open, setOpen] = React.useState(false);
  const [prodOpen, setProdOpen] = React.useState(false);
  const [specOpen, setSpecOpen] = React.useState(false);
  const [expertOpen, setExpertOpen] = React.useState(false);
  const [aboutOpen, setAboutOpen] = React.useState(false);

  // TODO: This is a mess, refactor — use a single state object
  const handleFullMenuOpen = () => {
    setOpen(!open);
    setProdOpen(false);
    setExpertOpen(false);
    setSpecOpen(false);
    setAboutOpen(false);
  };

  const handleProdOpen = () => {
    setProdOpen(!prodOpen);
    setExpertOpen(false);
    setAboutOpen(false);
    setSpecOpen(false);
  };

  const handleSpecOpen = () => {
    setProdOpen(false);
    setExpertOpen(false);
    setAboutOpen(false);
    setSpecOpen(!specOpen);
  };

  const handleExpertOpen = () => {
    setProdOpen(false);
    setExpertOpen(!expertOpen);
    setAboutOpen(false);
    setSpecOpen(false);
  };

  const handleAboutOpen = () => {
    setProdOpen(false);
    setExpertOpen(false);
    setAboutOpen(!aboutOpen);
    setSpecOpen(false);
  };

  const classes = useStyles();

  const data = useStaticQuery(graphql`
    query TopNavItems {
      wpMenu(locations: { in: HEADER_MENU }) {
        menuItems {
          nodes {
            id
            label
            path
            parentId
            cssClasses
            MenuItemImage {
              image {
                localFile {
                  childImageSharp {
                    fluid(quality: 80, maxWidth: 1660) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            childItems {
              nodes {
                id
                label
                path
                cssClasses
                MenuItemImage {
                  image {
                    localFile {
                      childImageSharp {
                        fluid(quality: 80, maxWidth: 1660) {
                          ...GatsbyImageSharpFluid_withWebp
                        }
                      }
                    }
                  }
                }
                childItems {
                  nodes {
                    id
                    label
                    path
                    cssClasses
                  }
                }
              }
            }
          }
        }
      }
      wp {
        themeOptions {
          ThemeOptions {
            siteLogo {
              sourceUrl
            }
          }
        }
      }
    }
  `);

  let i = data.wpMenu.menuItems.nodes.length;

  while (i--) {
    if (data.wpMenu.menuItems.nodes[i].parentId != null) {
      data.wpMenu.menuItems.nodes.splice(i, 1);
    }
  }

  const productMenuItems = data?.wpMenu?.menuItems?.nodes[0]?.childItems?.nodes;

  return (
    <React.Fragment>
      <div className={`brand-link  ${classes.brandLink}`}>
        <Link to="https://www.thomasdudley.co.uk/tyde/">
          <img src={tdLogo} alt="Thomas Dudley" />
        </Link>
      </div>
      <div
        className={`top-nav  ${classes.topNav}  ${classes.topNavTheme}`}
        open={open}
        data-home={props.home}
      >
        <div className="wrapper">
          <div className="site-logo">
            <Link to="/">
              <img
                src={data.wp.themeOptions.ThemeOptions.siteLogo.sourceUrl}
                alt=""
              />
            </Link>
          </div>
          <DesktopNav menuItems={data.wpMenu.menuItems.nodes} />
          <div className="action-buttons">
            <Link to="/product-search/">
              <img src={search} alt="Search" />
            </Link>
            <Link to="/quote-builder/">
              <strong>Quote Builder</strong>
              <img src={star} alt="Quote Builder" />
              {props.wishlist.length > 0 && (
                <span className="wl-no">{props.wishlist.length}</span>
              )}
            </Link>
          </div>
          <button
            className="menu-trigger"
            onClick={handleFullMenuOpen}
            onKeyDown={handleFullMenuOpen}
          >
            <span />
            Menu
          </button>
        </div>
      </div>

      <div className={`mobile-nav  ${classes.mobileNav}`} open={open}>
        <div className="action-buttons">
          <Link to="/product-search/">
            <img src={search} alt="Search" />
          </Link>
          <Link to="/quote-builder/">
            <img src={star} alt="Quote Builder" />
            <span className="wl-no">3</span>
          </Link>
        </div>
        <div className="actions">
          <button
            className="close"
            onClick={handleFullMenuOpen}
            onKeyDown={handleFullMenuOpen}
          >
            Close
          </button>
        </div>

        <div className="top-level">
          {productMenuItems.length !== 0 && (
            <div className="nav-item products" open={prodOpen}>
              <button onClick={handleProdOpen} onKeyDown={handleProdOpen}>
                {ReactHtmlParser(data.wpMenu.menuItems.nodes[0].label)}
              </button>
              <div className="nav-links">
                {productMenuItems.map((menuItem) => (
                  <Link to={menuItem.path} className="full-link">
                    <span className="content">
                      {/* FIXME: This is ridiculous style properly */}
                      <span></span>
                      <span>{ReactHtmlParser(menuItem.label)}</span>
                    </span>
                    {menuItem?.MenuItemImage?.image ? (
                      <span className="image">
                        <Img
                          fluid={
                            menuItem.MenuItemImage.image.localFile
                              .childImageSharp.fluid
                          }
                        />
                      </span>
                    ) : null}
                  </Link>
                ))}
              </div>
            </div>
          )}

          {data.wpMenu.menuItems.nodes[1].childItems !== null && (
            <div className="nav-item expertise" open={specOpen}>
              <button onClick={handleSpecOpen} onKeyDown={handleSpecOpen}>
                {ReactHtmlParser(data.wpMenu.menuItems.nodes[1].label)}
              </button>
              <div className="nav-links">
                {data.wpMenu.menuItems.nodes[1].childItems.nodes.map(
                  (subSection) => (
                    <Link key={subSection.id} to={subSection.path}>
                      {ReactHtmlParser(subSection.label)}
                    </Link>
                  )
                )}
                <Link
                  to={data.wpMenu.menuItems.nodes[1].path}
                  className="full-link"
                >
                  <span className="content">
                    <span>Overview</span>
                    <span>
                      {ReactHtmlParser(data.wpMenu.menuItems.nodes[1].label)}
                    </span>
                  </span>
                  {data.wpMenu.menuItems.nodes[1].MenuItemImage.image !==
                    null && (
                    <span className="image">
                      <Img
                        fluid={
                          data.wpMenu.menuItems.nodes[1].MenuItemImage.image
                            .localFile.childImageSharp.fluid
                        }
                      />
                    </span>
                  )}
                </Link>
              </div>
            </div>
          )}

          {data.wpMenu.menuItems.nodes[2].childItems !== null && (
            <div className="nav-item about" open={expertOpen}>
              <button onClick={handleExpertOpen} onKeyDown={handleExpertOpen}>
                {ReactHtmlParser(data.wpMenu.menuItems.nodes[2].label)}
              </button>
              <div className="nav-links">
                {data.wpMenu.menuItems.nodes[2].childItems.nodes.map(
                  (subSection) => (
                    <Link key={subSection.id} to={subSection.path}>
                      {ReactHtmlParser(subSection.label)}
                    </Link>
                  )
                )}
                <Link
                  to={data.wpMenu.menuItems.nodes[2].path}
                  className="full-link"
                >
                  <span className="content">
                    <span>Overview</span>
                    <span>
                      {ReactHtmlParser(data.wpMenu.menuItems.nodes[2].label)}
                    </span>
                  </span>
                  {data.wpMenu.menuItems.nodes[2].MenuItemImage.image !==
                    null && (
                    <span className="image">
                      <Img
                        fluid={
                          data.wpMenu.menuItems.nodes[2].MenuItemImage.image
                            .localFile.childImageSharp.fluid
                        }
                      />
                    </span>
                  )}
                </Link>
              </div>
            </div>
          )}

          {data.wpMenu.menuItems.nodes[3].childItems.nodes.length !== 0 && (
            <div className="nav-item about" open={aboutOpen}>
              <button onClick={handleAboutOpen} onKeyDown={handleAboutOpen}>
                {ReactHtmlParser(data.wpMenu.menuItems.nodes[3].label)}
              </button>
              <div className="nav-links">
                {data.wpMenu.menuItems.nodes[3].childItems.nodes.map(
                  (subSection) => (
                    <Link key={subSection.id} to={subSection.path}>
                      {ReactHtmlParser(subSection.label)}
                    </Link>
                  )
                )}
                <Link
                  to={data.wpMenu.menuItems.nodes[3].path}
                  className="full-link"
                >
                  <span className="content">
                    <span>Overview</span>
                    <span>
                      {ReactHtmlParser(data.wpMenu.menuItems.nodes[3].label)}
                    </span>
                  </span>
                  {data.wpMenu.menuItems.nodes[3].MenuItemImage.image !==
                    null && (
                    <span className="image">
                      <Img
                        fluid={
                          data.wpMenu.menuItems.nodes[3].MenuItemImage.image
                            .localFile.childImageSharp.fluid
                        }
                      />
                    </span>
                  )}
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default connector(TopNav);
